import {securedFetchRequest} from '@bidmii/common/lib/util/FetchRequest';
import {useSetRecoilState} from 'recoil';
import {sessionUidState} from '../../../recoil/user/userAtom';
import {getOS, whoAmI} from '../../../util/DeviceMetadataUtils';
import packageInfo from '../../../../package.json';
import * as Sentry from '@sentry/react';

const useRecordSession = (props?: $TSFixMe) => {
  const setSessionUid = useSetRecoilState(sessionUidState);

  const recordSession = (user: $TSFixMe, sessionRecordingUrl: $TSFixMe) => {
    let browserCoords = {};
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        browserCoords = {
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude,
        };
      },
      (error) => {
        console.warn(error);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      },
    );
    const browserInfo = whoAmI();
    const systemInfo = getOS();

    const body = {
      deviceName: browserInfo,
      osVersion: systemInfo.OS + ' ' + systemInfo.Platform,
      appVersion: packageInfo.version,
      sessionStart: new Date().toISOString(),
      deviceType: 'BROWSER',
      latitude: (browserCoords as $TSFixMe)?.latitude,
      longitude: (browserCoords as $TSFixMe)?.longitude,
      utmSource: localStorage.getItem('utm_source') || '',
      utmMedium: localStorage.getItem('utm_medium') || '',
      utmCampaign: localStorage.getItem('utm_campaign') || '',
      utmTerm: localStorage.getItem('utm_term') || '',
      utmContent: localStorage.getItem('utm_content') || '',
      sessionRecordingUrl: sessionRecordingUrl,
      timezone: '',
    };

    try {
      body.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (e) {}

    const url = user._links.sessions.href;
    return securedFetchRequest(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.text())
      .then((uid) => {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        uid && setSessionUid(uid);
        if (
          localStorage.getItem('utm_source') !== null &&
          // @ts-expect-error ts-migrate(2551) FIXME: Property 'utm_source' does not exist on type '{ de... Remove this comment to see the full error message
          body.utm_source === localStorage.getItem('utm_source')
        ) {
          localStorage.removeItem('utm_source');
          localStorage.removeItem('utm_medium');
          localStorage.removeItem('utm_campaign');
          localStorage.removeItem('utm_term');
          localStorage.removeItem('utm_content');
        }
      })
      .catch((err) => {
        process.env.NODE_ENV === 'development' && console.error(err);
        Sentry.withScope(function (scope) {
          scope.setTag('section', 'useRecordSession.tsx recordSession()');
          Sentry.captureException(err);
        });
      });
  };

  return recordSession;
};

export default useRecordSession;
