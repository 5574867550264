import React from 'react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {LicenseInfo} from '@mui/x-license';

// @ts-ignore
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_LICENCE_KEY);

import {createRoot} from 'react-dom/client';
import {RecoilRoot} from 'recoil';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <RecoilRoot>
    <App />
  </RecoilRoot>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

/*
ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root'),
);
*/
